import { useEffect } from "react";
import { useRouter } from "next/router";

import * as gtag from "lib/gtag";
import "styles/globals.css";
import Head from "next/head";

const App = ({ Component, pageProps }) => {
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = (url) => {
      const parsed = new URL(url);
      /*gtag.set({
        content_group1: step,
      });*/
      gtag.pageview(url);
    };
    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router.events]);

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="copyright" content="AwardJoy. All rights reserved." />
        <meta property="og:site_name" content="AwardJoy" />
        <meta property="og:type" content="website" />

        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#00affa" />
        <link
          rel="preload"
          href="//cdn.awardjoy.com/fonts/ProximaNova-Reg-webfont.woff2"
          as="font"
          type="font/woff2"
        />
        <link
          rel="preload"
          href="//cdn.awardjoy.com/fonts/ProximaNova-Sbold-webfont.woff2"
          as="font"
          type="font/woff2"
        />
        <link
          rel="preload"
          href="//cdn.awardjoy.com/fonts/ProximaNova-Bold-webfont.woff2"
          as="font"
          type="font/woff2"
        />

        <meta name="msapplication-TileColor" content="#ff0000" />
        <meta name="theme-color" content="#000000" />
        <meta property="fb:pages" content="100503291808730" />
      </Head>
      <Component {...pageProps} />
    </>
  );
};

export default App;
