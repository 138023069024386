export const GOOGLE_ANALYTICS_ID = "UA-177630334-1";

function gtag() {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(arguments);
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url) => {
  gtag("config", GOOGLE_ANALYTICS_ID, {
    page_path: url,
  });
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }) => {
  gtag("event", action, {
    event_category: category,
    event_label: label,
    value: value,
  });
};

export const set = (params) => {
  gtag("set", params);
};
